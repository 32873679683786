import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PageLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CLHbid = makeShortcode("CLHbid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Private Sale`}</h1>
    <p>{`Private sale is the most basic selling platform that has been around for centuries. One of its benefits is it that it saves on third party fees. Private Sale usually involves limited marketing by the Seller, either online or in conventional media, or it may be as basic as a deal made with a neighbor at the kitchen table. This platform often fails to maximize value due to limited exposure of the property and the struggle owners have in negotiating with a neighbor or tenant. Further, a Private Sale can often create a division between neighbors, as some may feel they were not given the same opportunity as the chosen Buyer.`}</p>
    <h2>{`There’s a better, easier way that covers the downfalls of a private sale.`}</h2>
    <p>{`Let’s take a look at some of the advantages to using `}<CLHbid mdxType="CLHbid" />{`.`}</p>
    <p>{`We take the stress out of selling. You are not forced to choose between friends, neighbours or even relatives. They are all given an equal chance. We use a fully transparent platform where no “special favours” or unfair advantages are possible. All bidding is confidential with only the name of the highest bidder given to the Seller.`}</p>
    <p>{`Here’s where it gets even better: Since we use “state-of-the-art” target marketing, every auction receives proper exposure. Your land is seen using still and video photography. We incorporate drone technology as well as rotary winged aerial photography so that potential Buyers can fully appreciate the farm or ranch you are selling. We market your property with strong signage, extensive digital media coverage, direct targeted mailers in your community as well as other advertising specific for your property. Our goal is to spread the word about your land and generate maximum exposure. After all, the more people who know about your property, the better your chances of a successful result.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      